@use "../../../../master.scss";

.feedback-modal {
  padding: 20px 0 !important;
  .ant-modal-content {
    padding: 50px 55px 40px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
      @include master.media-smalldevice {
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        &-icon {
          font-size: 14px;
        }
      }
    }
    .top-header {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid master.$grey;

      .title {
        font-size: 40px;
        font-family: master.$font-arial-bold;
        padding-left: 20px;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: calc(100% - 30px);
          top: 15px;
          left: 0;
          background: master.$theme-color-primary;
        }
        @include master.media-smalldevice {
          font-size: 27px;
          padding-left: 10px;
          &::before {
            height: calc(100% - 18px);
            top: 8px;
            width: 4px;
          }
        }
      }
      .description {
        font-size: 16px;
        @include master.media-smalldevice {
          font-size: 14px;
        }
      }
    }
    .sub-title,
    .field-label {
      margin-top: 20px;
      font-size: 15px;
      line-height: 28px;
      @include master.media-smalldevice {
        font-size: 14px;
        line-height: 25px;
      }
    }
    .field-label {
      display: block;
      font-family: master.$font-arial-bold;
      margin-bottom: 10px;
      @include master.media-smalldevice {
        font-size: 14px;
      }
    }
    .statements {
      margin-top: 20px;
      margin-bottom: 30px;
      .statement-item {
        margin-bottom: 8px;
        @include master.flex-end();
        label {
          position: absolute;
          left: 0;
          font-family: master.$font-arial-bold;

          @include master.media-smalldevice {
            position: unset;
            font-size: 13px;
            margin-bottom: 5px;
          }
        }
        .ant-form-item {
          margin: 0;
        }
        @include master.media-smalldevice {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .error-message {
        color: master.$red;
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .get-updates {
      margin-bottom: 10px;
      font-size: 15px;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
      }
      @include master.media-smalldevice {
        font-size: 13px;
      }
    }

    .upload-photo {
      display: flex;
      gap: 10px;
      .thumb {
        width: 28px;
        border-radius: 4px;
        height: 28px;
        border: 1px solid master.$dark-grey;
        color: master.$dark-grey;
        @include master.flex-center();
      }
      .uploader {
        background: master.$light-grey;
        border: none;
        padding: 6px 16px;
        border-radius: 50px;
        font-family: master.$font-arial-bold;
        input {
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
        }
      }
    }

    .images-box {
      margin: 10px 0 30px;
      overflow: hidden;
      width: 100%;
      overflow-x: auto;

      .selected-images {
        width: max-content;
        display: flex;
        gap: 10px;

        .image-item {
          border-radius: 8px;
          margin-top: 15px;
          width: 80px;
          height: 80px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          .remove-btn {
            z-index: 99;
            position: absolute;
            border: none;
            right: -8px;
            top: -8px;
            width: 20px;
            height: 20px;
            font-size: 12px;
            background: master.$grey;
            @include master.flex-center();
            border-radius: 50px;
            cursor: pointer;
            transition: 0.3s all;
            opacity: 0;
          }

          &::before {
            pointer-events: none;
            border-radius: 8px;
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.3);
            z-index: 2;
            transition: 0.3s all;
            opacity: 0;
          }

          &:hover {
            &::before,
            .remove-btn {
              opacity: 1;
            }
          }
          @include master.media-mobile {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .action-box {
      margin-top: 40px;
      @include master.flex-between();

      button {
        cursor: pointer;
        border: none;
        font-size: 16px;
        min-width: 140px;
        border-radius: 30px;
        padding: 12px 25px;
        font-family: master.$font-arial-bold;

        &:nth-child(1) {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
    @include master.media-smalldevice {
      padding: 30px 25px 20px;
    }
  }
}
