@use "../../../../../master.scss";

.create-group {
    .ant-modal-close {
        top: 20px !important;
    }

    .ant-modal-content {
        .modal-title {
            font-size: 22px;
        }

        .content-box {
            margin-top: 20px;

            input,
            textarea {
                font-size: 16px;
            }

            .tmp-inputbox {
                margin-top: 2px;
            }

            .bottom-wrapper {
                margin-top: 25px;
                @include master.flex-end();

                button {
                    font-size: 15px;
                    padding: 10px 36px;
                }
            }
        }
    }
}