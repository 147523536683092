@use "../../../../../../../master.scss";

.setting-sidebar {
  .interface-accordion {
    .project-keys {
      position: relative;
      margin-bottom: 10px;
      @include master.flex-start();
      p {
        position: relative;
        top: 3px;
      }
      button {
        background: transparent;
        border: none;
        width: 30px;
        height: 30px;
        @include master.flex-center();
        cursor: pointer;
        transition: 0.3s all;
        position: absolute;
        border-radius: 5px;
        right: 35px;
        &:hover {
          background: master.$light-grey;
        }
        &:last-child {
          right: 0;
        }
      }
    }
    .regenerate-key {
      background: master.$light-grey;
      border-radius: 50px;
      font-size: 13px;
      color: master.$black;
      margin-bottom: 15px;
      &:hover {
        background: master.$grey;
      }
    }
    .suffix-fields {
      @include master.flex-start();
      > div {
        width: calc(100% - 30px);
      }
      .suffix-btn {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 18px;
        cursor: pointer;
        background: transparent;
        border: none;
        color: master.$theme-color;
      }
    }
    .icon-uploader {
      @include master.flex-start();
      gap: 15px;
      margin-top: 10px;
      margin-bottom: 20px;
      .ant-image {
        width: 80px;
        height: 80px;
      }
      .action-btns {
        height: 80px;
        width: calc(100% - 90px);
        @include master.flex-center-column();
        gap: 12px;
        button {
          width: 100%;
          border-radius: 50px;
          margin: 0;
        }
      }
    }
    .conversation-starters {
      margin-top: 25px;
      margin-bottom: 10px;
      .ant-form-item {
        margin-bottom: 10px !important;
      }
      .title {
        margin-bottom: 5px;
        .suffix-btn {
          font-size: 16px;
          right: 1px;
        }
      }
      .group-questions {
        position: relative;
        border-left: 7px solid master.$theme-color-primary;
        padding-left: 10px;
        input {
          padding-right: 30px !important;
        }

        .remove-btn {
          position: absolute;
          right: 10px;
          top: 10px;
          color: master.$dark-grey;
          cursor: pointer;
        }
      }
      .add-question {
        background: transparent;
        border: none;
        font-size: 12px;
        margin-left: 17px;
        text-decoration: underline;
        float: right;
        cursor: pointer;
        margin-bottom: 20px;
      }
      .suffix-btn {
        color: master.$black;
        &.delete-group {
          color: master.$red;
          font-size: 14px;
        }
      }
    }
  }
}
