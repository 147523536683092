@use "../../../../../master.scss";

.add-user {
    .ant-modal-close {
        top: 20px !important;
    }

    .ant-modal-content {
        .modal-title {
            font-size: 22px;
        }

        .content-box {
            margin-top: 20px;

            input,
            .ant-select-selection-placeholder,
            .ant-select-selection-item {
                font-size: 16px !important;
            }

            .ant-select-selector {
                padding: 6px 10px;
            }

            .bottom-wrapper {
                margin-top: 25px;
                @include master.flex-end();

                button {
                    font-size: 15px;
                    padding: 10px 36px;
                }
            }
        }
    }
}