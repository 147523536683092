@use "../../../master.scss";

.create-advance-project-wrapper {
    .ant-modal-close {
        width: 40px;
        height: 40px;
        background: master.$light-grey;
        color: master.$black;
        border-radius: 50px;
        @include master.flex-center();

        &:hover {
            background: master.$grey;
        }

        @include master.media-mobile {
            width: 25px;
            height: 25px;

            .ant-modal-close-x {
                font-size: 13px;
            }
        }
    }

    .content-box {
        padding: 30px 40px 30px 30px;

        .title-wrapper {
            @include master.flex-between();
            margin-bottom: 8px;

            h2 {
                font-size: 28px;
                font-family: master.$font-arial-bold;

                @include master.media-mobile {
                    font-size: 20px;
                }
            }

            .current-step {
                font-size: 14px;
                font-family: master.$font-arial-bold;

                @include master.media-mobile {
                    font-size: 13px;
                }
            }

            @include master.media-mobile {
                @include master.flex-start-column()
            }
        }

        .card-box {
            margin-top: 40px;
            border-left: 7px solid master.$theme-color-primary;
            padding-left: 15px;
            width: 630px;
            max-width: 100%;

            .title {
                font-family: master.$font-arial-bold;
                font-size: 18px;
                margin-bottom: 5px;

                .thumb {
                    font-size: 17px;
                    margin-right: 8px;

                    @include master.media-mobile {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }

                .suffix {
                    margin-left: 5px;
                    color: master.$darkest-grey;
                    cursor: pointer;
                }

                &.small {
                    font-size: 14px;

                    .thumb {
                        font-size: 13px;

                        @include master.media-mobile {
                            font-size: 12px;
                        }
                    }

                    @include master.media-mobile {
                        font-size: 13px;
                    }
                }

                @include master.media-mobile {
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }

            .description {
                width: 650px;
                max-width: 100%;
                font-size: 14px;

                span,
                a {
                    color: master.$theme-color;
                    font-family: master.$font-arial-bold;
                    margin: 0 4px;
                    text-decoration: underline;
                }

                @include master.media-mobile {
                    font-size: 12px;
                }
            }

            .content {
                padding-top: 8px;

                .user-info {
                    margin-top: 24px;
                    @include master.flex-start-column();
                    gap: 10px;

                    li {
                        display: block;
                        font-size: 14px;
                        @include master.flex-start();

                        .title {
                            font-family: master.$font-arial-bold;
                            width: 70px;
                            font-size: 14px;
                            margin: 0;

                            @include master.media-mobile {
                                font-size: 13px;
                            }
                        }

                        @include master.media-mobile {
                            font-size: 13px;
                        }
                    }

                    @include master.media-mobile {
                        margin-top: 10px;
                    }
                }

                .warning-text {
                    color: master.$theme-color;
                    font-family: master.$font-arial-bold;

                    @include master.media-mobile {
                        font-size: 13px;
                    }
                }

                .coming-soon {
                    font-family: master.$font-arial-bold;
                    font-size: 14px;
                    margin-top: 15px;
                    color: master.$theme-color;
                }

                .regenerate-secret-btn {
                    background: master.$offwhite;
                    color: master.$black;
                    font-size: 14px;
                    padding: 12px 50px;

                    &:hover {
                        background: master.$light-grey;
                    }

                    @include master.media-mobile {
                        font-size: 13px;
                        padding: 10px;
                        width: 100%;
                    }
                }

                .database-content {
                    margin-bottom: 25px;

                    h4 {
                        font-family: master.$font-arial-bold;
                        font-size: 18px;

                        @include master.media-mobile {
                            font-size: 14px;
                        }
                    }

                    @include master.media-mobile {
                        margin-bottom: 15px;

                        p {
                            font-size: 12px;
                        }
                    }
                }

                .url-view-box {
                    padding: 12px 16px;
                    @include master.flex-start();
                    gap: 15px;
                    display: inline-flex;
                    background: master.$light-grey;
                    border-radius: 8px;

                    .title {
                        margin: 0;
                        font-size: 14px;
                        font-family: master.$font-arial-bold;

                        @include master.media-mobile {
                            font-size: 13px;
                        }
                    }

                    .value {
                        font-size: 14px;
                        color: master.$theme-color;
                        text-decoration: underline;
                        max-width: 240px;
                        @include master.text-wrap();

                        @include master.media-mobile {
                            font-size: 13px;
                            max-width: 100%
                        }
                    }

                    .action-box {
                        @include master.flex-center();
                        gap: 5px;

                        svg {
                            color: master.$theme-color;
                            font-size: 12px;
                            cursor: pointer;
                            width: 20px;

                            @include master.media-mobile {
                                width: 18px;
                                font-size: 13px;
                            }
                        }

                        @include master.media-mobile {
                            position: absolute;
                            top: 8px;
                            right: 10px;
                        }
                    }

                    &.generation-key {
                        .title {
                            min-width: 100px;
                        }
                    }

                    &.api-key .value {
                        min-width: 200px;
                    }

                    @include master.media-mobile {
                        padding: 8px 10px;
                        @include master.flex-start-column();
                        gap: 5px;
                    }
                }

                .enabled-box {
                    background: master.$grey;
                    @include master.flex-center();
                    display: inline-flex;
                    padding: 7px 22px;
                    border-radius: 30px;
                    font-size: 14px;
                    font-family: master.$font-arial-bold;
                    gap: 4px;
                    opacity: 0.4;

                    svg {
                        font-size: 15px;
                        visibility: hidden;

                        @include master.media-mobile {
                            font-size: 12px;
                        }
                    }

                    @include master.media-mobile {
                        font-size: 13px;
                        padding: 5px 20px;
                    }

                    &.enable {
                        opacity: 1;
                        background: master.$theme-color-primary;

                        svg {
                            visibility: visible;
                        }
                    }
                }

                .checkbox-listing {
                    flex-direction: column;
                    gap: 15px;

                    @include master.media-mobile {
                        gap: 7px;

                        label {
                            font-size: 13px;
                        }
                    }
                }

                .global-tags {
                    margin-top: 20px;

                    h3 {
                        font-family: master.$font-arial-bold;
                        font-size: 16px;
                        margin-bottom: 8px;

                        .thumb {
                            cursor: pointer;
                            margin-left: 5px;
                            font-size: 13px;
                            color: master.$theme-color;
                        }
                    }

                    .ant-tag {
                        background: master.$light-grey;
                        font-size: 13px;
                        padding: 3px 12px;
                        border-radius: 50px;

                        button {
                            background: none;
                            border: none;
                        }

                        &.add-tag {
                            padding: 2px;
                            width: 25px;
                            height: 25px;
                            @include master.flex-center();
                            cursor: pointer;

                            &.disabled {
                                pointer-events: none;
                                opacity: 0.5;
                            }
                        }

                        &.ant-tag-hidden {
                            display: block;
                        }
                    }

                    input {
                        width: 100px !important;
                        font-size: 13px;
                        padding: 3px 10px;
                        border-radius: 50px;
                        margin-inline-end: 8px;
                    }
                }

                .files-listing-wrapper {
                    margin-top: 25px;

                    .row {
                        @include master.flex-start();
                        border-bottom: 1px solid master.$border-color;
                        padding-right: 40px;

                        .item {
                            padding: 15px 0;
                            padding-right: 5px;
                            @include master.text-wrap();
                            display: block;

                            &.db {
                                width: 20%;

                                @include master.media-mobile {
                                    width: 25%;
                                }
                            }

                            &.file {
                                width: 25%;

                                @include master.media-mobile {
                                    width: 30%;
                                }
                            }

                            &.tag {
                                width: 55%;

                                @include master.media-mobile {
                                    width: 45%;
                                }

                                .ant-tag {
                                    background: master.$light-grey;
                                    font-size: 12px;
                                    padding: 2px 12px;
                                    border-radius: 50px;

                                    button {
                                        background: none;
                                        border: none;
                                    }

                                    &.add-tag {
                                        padding: 2px;
                                        width: 25px;
                                        height: 25px;
                                        @include master.flex-center();
                                        cursor: pointer;
                                    }

                                    &.ant-tag-hidden {
                                        display: block;
                                    }
                                }

                                input {
                                    width: 85px;
                                    font-size: 12px;
                                    padding: 2px 10px;
                                    border-radius: 50px;
                                    margin-inline-end: 8px;
                                }
                            }

                            @include master.media-mobile {
                                padding: 10px 0;
                                padding-right: 5px;
                                font-size: 13px;
                            }
                        }

                        .delete-btn {
                            position: absolute;
                            right: 0;
                            color: master.$theme-color;
                            cursor: pointer;
                        }

                        &.head {
                            .item {
                                padding: 5px 0;
                                padding-right: 5px;
                                font-family: master.$font-arial-bold;
                            }
                        }
                    }
                }

                .urlform-wrapper {
                    @include master.flex-start();
                    gap: 10px;

                    button {
                        background: master.$light-grey;
                        color: master.$black;
                        font-size: 14px;
                        font-family: master.$font-arial-bold;
                        min-width: 120px;
                        border-radius: 30px;
                        padding: 12px;

                        &:hover {
                            background: master.$grey;
                        }

                        @include master.media-mobile {
                            min-width: calc(100% - 170px);
                            padding: 9px;
                            font-size: 13px;
                        }
                    }

                    @include master.media-mobile {
                        .ant-input {
                            width: 160px;
                        }
                    }
                }

                .urls-listing-wrapper {
                    .item {
                        @include master.text-wrap();
                        border-bottom: 1px solid master.$border-color;
                        padding: 15px 0;
                        padding-right: 30px;

                        .delete-btn {
                            position: absolute;
                            right: 0;
                            color: master.$theme-color;
                            cursor: pointer;

                            @include master.media-mobile {
                                font-size: 12px;
                                top: 14px;
                            }
                        }

                        @include master.media-mobile {
                            padding: 9px 0;
                            font-size: 13px;
                            padding-right: 30px;
                        }
                    }
                }

                .customize-box {
                    margin: 4px 0 12px;

                    .label {
                        font-family: master.$font-arial-bold;
                        font-size: 14px;
                        margin-right: 15px;

                        @include master.media-mobile {
                            font-size: 13px;
                            margin-right: 8px;
                        }
                    }

                    @include master.media-mobile {
                        .ant-switch {
                            min-width: 30px;
                            height: 18px;

                            .ant-switch-handle {
                                width: 14px;
                                height: 14px;
                            }

                            &.ant-switch-checked {
                                .ant-switch-handle {
                                    inset-inline-start: calc(100% - 16px);
                                }
                            }
                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 0;
                }

                .temperature-field {
                    margin-top: 15px;

                    @include master.media-mobile {
                        margin-top: 0;

                        .customize-box {
                            margin-bottom: 30px;
                        }
                    }
                }

                .textarea-wrapper {
                    .count-length {
                        position: absolute;
                        right: 0;
                        top: -22px;
                        font-size: 12px;

                        &.error {
                            color: master.$error-color;
                        }

                        @include master.media-mobile {
                            top: 0;
                            font-size: 10px;
                        }
                    }

                    label {
                        margin-bottom: 4px;
                        display: block;

                        @include master.media-mobile {
                            font-size: 12px;
                            margin-bottom: 10px;
                        }
                    }

                    &.labeled {
                        .count-length {
                            top: 2px;

                            @include master.media-mobile {
                                top: auto;
                                bottom: 77px;
                            }
                        }

                        @include master.media-mobile {
                            padding-top: 0;
                        }
                    }

                    @include master.media-mobile {
                        padding-top: 15px;
                    }
                }

                .ant-form-item-explain-error {
                    position: absolute;
                }

                input[type="text"],
                .ant-select {
                    width: 384px;
                    max-width: 100%;
                }
            }

            @include master.media-mobile {
                margin-top: 20px;
                padding-left: 10px;
                border-left-width: 5px;
            }
        }

        .bottom-wrapper {
            margin-top: 65px;
            @include master.flex-end();
            min-height: 45px;

            button {
                font-size: 16px;
                min-width: 160px;
                border-radius: 30px;
                padding: 12px 16px;
                font-family: master.$font-arial-bold;

                &.previous-step-btn {
                    position: absolute;
                    left: 0;
                    background: master.$light-grey;
                    color: master.$black;

                    &:hover {
                        background: master.$grey;
                    }
                }

                @include master.media-mobile {
                    min-width: 100px;
                    padding: 8px 16px;
                    font-size: 14px;
                }
            }

            @include master.media-mobile {
                margin-top: 30px;
            }
        }

        .create-project-step {
            margin-top: 50px;

            .content-wrapper {
                @include master.flex-center-column();

                h2 {
                    font-size: 38px;
                    font-family: master.$font-arial-bold;
                    padding-bottom: 7px;
                    margin-bottom: 25px;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 10%;
                        width: 80%;
                        height: 8px;
                        background: master.$theme-color-primary;

                        @include master.media-mobile {
                            height: 6px;
                        }
                    }

                    @include master.media-mobile {
                        font-size: 26px;
                        margin-bottom: 15px;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 30px;
                    text-align: center;

                    @include master.media-mobile {
                        font-size: 13px;
                        margin-bottom: 20px;
                    }
                }

                ul {
                    max-width: 100%;

                    li {
                        @include master.flex-start();
                        font-size: 16px;
                        margin-bottom: 15px;
                        flex-wrap: wrap;

                        .title {
                            font-family: master.$font-arial-bold;
                            width: 180px;

                            @include master.media-mobile {
                                width: 140px;
                            }
                        }

                        @include master.media-mobile {
                            font-size: 13px;
                            margin-bottom: 10px;

                        }
                    }
                }
            }

            .bottom-wrapper {
                margin-top: 10px;
            }

            @include master.media-mobile {
                margin-top: 30px;
            }
        }

        .congratulation-step {
            margin-top: 50px;

            .content-wrapper {
                @include master.flex-center-column();

                h2 {
                    font-size: 38px;
                    font-family: master.$font-arial-bold;
                    padding-bottom: 7px;
                    margin-bottom: 25px;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 10%;
                        width: 80%;
                        height: 8px;
                        background: master.$success-color;

                        @include master.media-mobile {
                            height: 6px;
                        }
                    }

                    @include master.media-mobile {
                        font-size: 26px;
                        margin-bottom: 15px;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 60px;
                    text-align: center;

                    @include master.media-mobile {
                        font-size: 13px;
                        margin-bottom: 20px;

                    }
                }
            }

            .bottom-wrapper {
                justify-content: center;
            }

            @include master.media-mobile {
                margin-top: 30px;
            }
        }

        @include master.media-mobile {
            padding: 10px 0;
        }
    }
}