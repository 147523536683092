@use "../../../../master.scss";

.project-knowledge-base {
  margin: 30px 0 !important;
  max-width: calc(100% - 60px);
  .ant-modal-content {
    padding: 20px 40px;
    .top-header {
      margin: 30px 0;
      padding-bottom: 30px;
      border-bottom: 1px solid master.$border-color;
      .title {
        color: master.$theme-color;
        font-size: 40px;
        font-family: master.$font-arial-bold;
      }
      p {
        font-size: 17px;
        width: 900px;
        max-width: 100%;
      }
    }
    .sub-title {
      font-size: 20px;
      font-family: master.$font-arial-bold;
      color: master.$theme-color;
    }
    .sub-description {
      font-size: 15px;
      margin-top: 3px;
      width: 700px;
      max-width: 100%;
    }
    .rotate-icon {
      animation: rotate 2s linear infinite;
      display: inline-block;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
