@use "../../master.scss";

.upload-box {
    margin-top: 8px;
    background: master.$ash-white;
    padding: 24px 0;
    border: 1px dashed master.$border-color;
    border-radius: 8px;
    @include master.flex-center-column();
    min-height: 167px;

    .thumb {
        font-size: 35px;
        color: master.$dark-grey;
    }

    p {
        margin-top: 8px;
        margin-bottom: 8px !important;
        color: master.$dark-grey;
        font-size: 14px;
        line-height: 17px;
        width: 158px;
        max-width: 100%;
        text-align: center;
    }

    button {
        border-radius: 50px;
    }
    &.active{
        border-color: master.$theme-color;
        background: master.$white;
    }
}