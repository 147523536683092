@use "../../../../../master.scss";

.message-feedback-modal {
  box-shadow: 0px 3px 6px #00000029;

  .ant-modal-close {
    top: 30px !important;
    right: 30px !important;
  }
  .content-box {
    padding: 30px 10px;

    .title-wrapper {
      padding: 0 20px 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid master.$grey;

      h2 {
        font-size: 34px;
        padding-left: 12px;
        font-family: master.$font-arial-bold;

        @include master.media-smalldevice {
          font-size: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 12px);
          width: 7px;
          height: 24px;
          background: master.$theme-color-primary;

          @include master.media-mobile {
            height: 6px;
          }
        }
      }
      p {
        font-size: 14px;
        color: master.$dark-grey;

        @include master.media-smalldevice {
          font-size: 13px;
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
    }
    .feedback-form {
      padding: 0 20px;
      h4 {
        font-family: master.$font-arial-bold;
        margin-top: 20px;
        font-size: 16px;
      }
      p {
        font-size: 13px;
      }
      .star-rating {
        margin-top: 8px;
        li {
          margin-inline-end: 3px;
        }
      }
      textarea {
        height: 160px;
        margin-top: 5px;
        &:disabled {
          color: master.$black;
          background: transparent;
        }
      }
    }

    .bottom-wrapper {
      margin-top: 35px;
      @include master.flex-end();

      button {
        font-size: 16px;
        min-width: 160px;
        border-radius: 30px;
        padding: 12px 25px;
        font-family: master.$font-arial-bold;

        &.back-btn {
          position: absolute;
          left: 0;
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }

      @include master.media-smalldevice {
        margin-top: 30px;
      }
    }

    @include master.media-smalldevice {
      padding: 30px 0;
    }
  }
}
