@use '../../master.scss';

.markdown-body {
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;

  &.data-message-highlight {
    background-color: master.$grey;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5em;
    font-family: master.$font-arial-bold;
    margin-bottom: 0.75em;
  }

  strong {
    font-family: master.$font-arial-bold;
  }

  pre,
  code {
    word-break: break-word;
    white-space: break-spaces;
  }

  p {
    margin: 1em 0;
  }

  a {
    color: #2563eb;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    padding-left: 1.5em;
    margin: 1em 0;
    li {
      margin-bottom: 5px;
    }
  }

  blockquote {
    border-left: 4px solid #9ca3af;
    margin: 1em 0;
    padding-left: 1em;
    color: #374151;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0;

    th,
    td {
      border: 1px solid #d1d5db;
      padding: 0.5em;
    }

    th {
      background-color: #f9fafb;
    }
  }

  .code-block-wrapper {
    margin: 1em 0;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    overflow: hidden;

    .code-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: #e5e7eb;
      border-bottom: 1px solid #d1d5db;

      .language-tag {
        font-size: 0.875rem;
        color: #1f2937;
        text-transform: uppercase;
      }
      button {
        border: none;
        display: block;
        border-radius: 8px;
        width: 35px;
        height: 35px;
        border: 1px solid master.$dark-grey;
        font-size: 15px;
        cursor: pointer;
        transition: 0.3s all;
        @include master.flex-center();

        &.active {
          color: master.$theme-color !important;
        }
      }
    }
  }

  :not(pre) > code {
    background-color: #e5e7eb;
    color: #1f2937;
    padding: 0.2em 0.4em;
    border-radius: 0.25rem;
    font-size: 1em;
    font-family: 'Fira Code', monospace;
  }
  @include master.media-tablet {
    * {
      font-size: 0.875rem;
    }

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1.125rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1rem;
    }

    code,
    pre {
      font-size: 0.75rem;
    }

    .code-block-wrapper .code-header .language-tag {
      font-size: 0.75rem;
    }
  }
  @include master.media-tablet {
    * {
      font-size: 0.875rem;
    }

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1.125rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1rem;
    }

    code,
    pre {
      font-size: 0.75rem;
    }

    .code-block-wrapper .code-header .language-tag {
      font-size: 0.75rem;
    }
  }
}
