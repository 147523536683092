@use "../../../../master.scss";

.intro-modal {
  box-shadow: 0px 3px 6px #00000029;

  .content-box {
    padding: 30px 10px;

    .title-wrapper {
      padding: 0 20px 30px;
      margin-bottom: 40px;
      border-bottom: 1px solid master.$grey;

      h2 {
        font-size: 34px;
        padding-left: 12px;
        font-family: master.$font-arial-bold;

        @include master.media-smalldevice {
          font-size: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 12px);
          width: 7px;
          height: 24px;
          background: master.$theme-color-primary;

          @include master.media-mobile {
            height: 6px;
          }
        }
      }
      p {
        font-size: 14px;
        color: master.$dark-grey;

        @include master.media-smalldevice {
          font-size: 13px;
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
    }

    .step-box {
      padding: 0 20px;
      &.intro-step {
        .video-box {
          background: master.$light-grey;
          height: 200px;
          color: master.$white;
          font-size: 40px;
          @include master.flex-center();
        }
        .description {
          color: master.$dark-text;
          margin-top: 15px;
        }
      }
      &.about-step {
        h4 {
          margin-bottom: 15px;
        }
        .options-box {
          margin-bottom: 40px;
          @include master.flex-start();
          flex-wrap: wrap;
          gap: 8px;
          button {
            padding: 8px 12px;
            border: 1px solid master.$light-grey;
            background: transparent;
            font-size: 14px;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.3s all;
            &:hover,
            &.active {
              border-color: master.$theme-color;
            }
            &.active {
              background: master.$theme-color;
              color: master.$white;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .bottom-wrapper {
      padding: 0 20px;
      margin-top: 60px;
      @include master.flex-between();
      .step-nav {
        @include master.flex-start();
        gap: 10px;
        button {
          border: none;
          cursor: pointer;
          width: 13px;
          height: 13px;
          display: block;
          background: master.$light-grey;
          border-radius: 50px;
          &.active {
            background: master.$dark-grey;
          }
        }
      }
      .action-box {
        @include master.flex-end();
        gap: 10px;
        button {
          font-size: 15px;
          min-width: 100px;
          border-radius: 30px;
          padding: 10px;
          font-family: master.$font-arial-bold;

          &.back-btn {
            background: master.$light-grey;
            color: master.$black;

            &:hover {
              background: master.$grey;
            }
          }

          @include master.media-smalldevice {
            min-width: 100px;
            padding: 9px 20px;
            font-size: 14px;
          }
        }
      }

      @include master.media-smalldevice {
        margin-top: 30px;
      }
    }

    @include master.media-smalldevice {
      padding: 30px 0;
    }
  }
}
