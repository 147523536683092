@use "../../master.scss";

.widget-container {
  height: 56px;
  width: 56px;
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: 30px;

  .thumb {
    position: relative;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      cursor: pointer;
      &.move {
        transition: 0.3s all;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
    &:hover {
      .move {
        opacity: 0.8;
      }
    }
  }

  .ant-popover {
    .ant-popover-content {
      margin: 0 10px;
    }

    .ant-popover-inner {
      padding: 2px 0;

      .react-resizable {
        position: relative;
        max-width: 100%;

        .react-resizable-handle {
          position: absolute;
          width: 20px;
          height: 20px;
          background-position: bottom right;
          background-repeat: no-repeat;
          background-origin: content-box;
          box-sizing: border-box;
          z-index: 9999;
          &-se {
            bottom: 0;
            right: 0;
            cursor: se-resize;
          }
          &-sw {
            bottom: 0;
            left: 0;
            cursor: sw-resize;
          }
          &-ne {
            top: 0;
            right: 0;
            cursor: ne-resize;
          }
          &-nw {
            top: 0;
            left: 0;
            cursor: nw-resize;
          }
        }

        .chat-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          height: 100%;
          overscroll-behavior: contain;

          .chat-header {
            position: relative;
            @include master.flex-start();
            gap: 0.5rem;
            padding: 1rem 1rem 10px 1rem;

            .thumb {
              width: 32px;
              height: 32px;
            }
            .title {
              font-family: master.$font-arial-bold;
              font-size: 16px;
            }

            .clear-btn {
              color: master.$border-color;
              position: absolute;
              background: transparent;
              border: none;
              right: 1rem;
              font-size: 15px;

              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .messages-listing {
            overflow-y: auto;
            overscroll-behavior: contain;
            flex: 1;
            margin: 0.5rem 0;
            padding: 0 1rem 15px;

            .scroll-target {
              height: 1px;
              width: 100%;
            }

            > li {
              display: block;
              overflow: hidden;
              font-size: 14px;
              margin-bottom: 10px;

              .markdown-body {
                line-height: 24px;
                p {
                  margin: 0;
                }
              }
              .loader-wrapper {
                justify-content: flex-start;
              }
              &.user .markdown-body {
                max-width: 80%;
                background-color: master.$light-black;
                border-radius: 500px;
                padding: 4px 15px;
                float: right;
              }
              &.bot {
                padding-bottom: 5px;
                .markdown-body {
                  max-width: 85%;
                  float: left;
                }
              }
            }
          }

          .chat-footer {
            flex-shrink: 0;
            bottom: 16px;
            width: 100%;
            height: 40px;
            padding: 0 1rem;
            @include master.flex-center();
            gap: 0.5rem;

            .ant-input {
              border-radius: 4px;
              height: 37px;

              &::placeholder {
                color: master.$black;
              }
            }

            .send-btn {
              border-radius: 100px;
              min-width: 40px;
              height: 40px;
              font-size: 16px;
              padding: 0;
              @include master.flex-center();

              svg {
                margin: 0;
              }
              &:disabled {
                opacity: 0.6;
              }
              @include master.media-smalldevice {
                min-width: 35px;
                height: 35px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .popover {
      color: master.$white;
      font-size: 14px;
      line-height: 24px;

      &.default {
        padding: 2px 12px;
      }
    }
  }
  @include master.media-smalldevice {
    width: 50px;
    height: 50px;
  }
}
