@use "../../master.scss";

.tag-input {
  width: 100%;
  height: auto;
  min-height: 35px;
  overflow-y: auto;
  @include master.flex-start();
  flex-wrap: wrap;
  padding: 2px 8px;
  border: 1px solid master.$border-color;
  border-radius: 6px;

  > * {
    margin: 2px;
  }

  @at-root #{&}__input {
    width: auto;
    flex-grow: 1;
    height: 24px;
    font-size: 14px;
    line-height: 1;
    background: transparent;
    color: master.$black;
    border: none;
    border-radius: 6px;
    outline: 0;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;

    &::placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: master.$dark-grey;
    }
    &:focus {
      border: none;
    }
  }

  @at-root #{&}__tag {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }

  @at-root #{&}__tag__content {
    outline: 0;
    border: none;
    background: transparent;
    white-space: nowrap;
    padding: 0 7px;
  }

  @at-root #{&}__tag__remove {
    position: relative;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background: transparent;
    border: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: " ";
      height: 10px;
      width: 1px;
      background-color: #848484;
    }
    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
    &:after {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    @at-root #{&}-readonly {
      width: 0;
      &:before,
      &:after {
        content: "";
        width: 0;
      }
    }
  }
  &.disabled {
    color: master.$disabled-input-color;
    background: master.$disabled-input-bg;
    cursor: not-allowed;
    border-color: master.$disabled-input-border;
    input {
      cursor: not-allowed;
    }
  }
}
