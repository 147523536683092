@use "../../master.scss";

.backdrop {
    position: fixed;
    background: #000;
    border: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.mobile {
        display: none;
        @include master.media-tablet {
            display: block;
        }
    }
}