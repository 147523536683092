@use "../../master.scss";

.modal-component {
    .ant-modal-content {

        .ant-modal-close {
            top: 16px;
            width: 32px;
            height: 32px;
            background: master.$light-grey;
            color: master.$black;
            border-radius: 50px;
            @include master.flex-center();

            &:hover {
                background: master.$grey;
            }

            @include master.media-mobile {
                width: 20px;
                height: 20px;

                .ant-modal-close-x {
                    font-size: 13px;
                }
            }
        }

        .modal-title {
            color: master.$theme-color;
            font-size: 18px;
            font-family: master.$font-arial-bold;
            letter-spacing: 0.5px;
            margin-bottom: 15px;
            font-size: 20px;
        }

        @include master.media-tablet {
            padding: 20px 15px;
        }
    }
}