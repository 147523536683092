@use "../../../../master.scss";

.report-problem {
  .ant-modal-content {
    padding: 50px 55px 60px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
      @include master.media-smalldevice {
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        &-icon {
          font-size: 14px;
        }
      }
    }
    .top-header {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid master.$grey;

      .title {
        font-size: 40px;
        font-family: master.$font-arial-bold;
        padding-left: 20px;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: calc(100% - 30px);
          top: 15px;
          left: 0;
          background: master.$theme-color-primary;
        }
        @include master.media-smalldevice {
          font-size: 27px;
          padding-left: 10px;
          &::before {
            height: calc(100% - 18px);
            top: 8px;
            width: 4px;
          }
        }
      }
      .description {
        font-size: 16px;
        @include master.media-smalldevice {
          font-size: 14px;
        }
      }
    }
    .sub-title {
      margin-top: 20px;
      font-size: 15px;
      margin-bottom: 10px;
      @include master.media-smalldevice {
        font-size: 14px;
      }
    }
    .projects-list {
      width: 100%;
      display: flex;
      flex-wrap: unset;
      flex-direction: column;
      max-height: 112px;
      overflow-y: auto;
    }
    .projects-list > label,
    .get-updates {
      margin-bottom: 10px;
      font-size: 15px;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
      }
      .ant-radio {
        .ant-radio-inner {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          &::after {
            position: absolute;
            top: 50%;
            inset-inline-start: 25%;
            margin-block-start: 0;
            margin-inline-start: 0;
            background: transparent;
            border-radius: 0;
            display: table;
            width: 5.7142857142857135px;
            height: 9.142857142857142px;
            border: 2px solid #fff;
            border-top: 0;
            border-inline-start: 0;
            transform: rotate(45deg) scale(0) translate(-50%, -50%);
            opacity: 0;
            content: "";
            transition: all 0.1scubic-bezier(0.71, -0.46, 0.88, 0.6),
              opacity 0.1s;
          }
        }
        &.ant-radio-checked {
          .ant-radio-inner {
            background-color: #1890ff;
            border-color: #1890ff;
            &::after {
              opacity: 1;
              transform: rotate(45deg) scale(1) translate(-50%, -50%);
              transition: all 0.2scubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            }
          }
        }
      }
      @include master.media-smalldevice {
        font-size: 13px;
      }
    }

    .upload-photo {
      display: flex;
      gap: 10px;
      .thumb {
        width: 28px;
        border-radius: 4px;
        height: 28px;
        border: 1px solid master.$dark-grey;
        color: master.$dark-grey;
        @include master.flex-center();
      }
      .uploader {
        background: master.$light-grey;
        border: none;
        padding: 6px 16px;
        border-radius: 50px;
        font-family: master.$font-arial-bold;
        input {
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
        }
      }
    }

    .images-box {
      margin: 10px 0 30px;
      overflow: hidden;
      width: 100%;
      overflow-x: auto;

      .selected-images {
        width: max-content;
        display: flex;
        gap: 10px;

        .image-item {
          border-radius: 8px;
          margin-top: 15px;
          width: 80px;
          height: 80px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          .remove-btn {
            z-index: 99;
            position: absolute;
            border: none;
            right: -8px;
            top: -8px;
            width: 20px;
            height: 20px;
            font-size: 12px;
            background: master.$grey;
            @include master.flex-center();
            border-radius: 50px;
            cursor: pointer;
            transition: 0.3s all;
            opacity: 0;
          }

          &::before {
            pointer-events: none;
            border-radius: 8px;
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(0, 0, 0, 0.3);
            z-index: 2;
            transition: 0.3s all;
            opacity: 0;
          }

          &:hover {
            &::before,
            .remove-btn {
              opacity: 1;
            }
          }
          @include master.media-mobile {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .action-box {
      margin-top: 40px;
      @include master.flex-between();

      button {
        cursor: pointer;
        border: none;
        font-size: 16px;
        min-width: 140px;
        border-radius: 30px;
        padding: 12px 25px;
        font-family: master.$font-arial-bold;

        &:nth-child(1) {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
    @include master.media-smalldevice {
      padding: 30px 25px 20px;
    }
  }
}
