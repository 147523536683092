@use "../../master.scss";

.table-wrapper {
  border-radius: 8px;

  .top-box {
    @include master.flex-end();
    min-height: 50px;

    h4 {
      position: absolute;
      left: 0;
      font-family: master.$font-arial-bold;
      font-size: 20px;

      @include master.media-smalldevice {
        position: unset;
        font-size: 18px;
      }
    }

    .search-box {
      max-width: 100%;
      @include master.flex-center();

      .thumb {
        position: absolute;
        left: 15px;
        z-index: 2;
        color: master.$theme-color;
        font-size: 18px;

        @include master.media-smalldevice {
          font-size: 16px;
        }
      }

      input {
        border: 1px solid master.$grey;
        border-radius: 8px;
        width: 400px;
        max-width: 100%;
        padding: 12px;
        padding-left: 45px;
        font-size: 16px;

        @include master.media-smalldevice {
          font-size: 14px;
          padding-left: 37px;
        }
      }
    }

    .action-box {
      @include master.flex-end();
      gap: 15px;
      margin-left: 15px;

      button {
        font-size: 16px;
        padding: 12px 26px;

        @include master.media-smalldevice {
          font-size: 14px;
          width: 100px;
          padding: 10px;
        }
      }

      @include master.media-smalldevice {
        margin: 0;
      }
    }

    @include master.media-smalldevice {
      @include master.flex-start-column();
      gap: 10px;
    }
  }

  .table-box {
    margin-top: 20px;

    .no-data-wrapper {
      padding: 50px 0;

      img {
        width: 60px;
      }

      h4 {
        color: master.$black;
        font-family: master.$font-arial-bold;
      }
    }

    td,
    th {
      font-size: 15px;
      min-width: 145px;

      @include master.media-smalldevice {
        font-size: 13px;
      }
      &.small-cell {
        min-width: 120px;
      }
    }
    tbody {
      tr:first-child {
        td {
          min-width: unset;
        }
      }
    }

    td {
      &:first-child {
        cursor: pointer;
      }

      a {
        color: inherit;
        background: red;
        display: block;
      }
    }

    .actions-box {
      display: flex;
      justify-content: flex-end;
      gap: 15px;

      svg {
        cursor: pointer;
        font-size: 16px;

        &.delete {
          color: master.$red;
        }
      }
    }
  }

  &.bordered {
    border: 1px solid master.$grey;
    padding: 15px;
  }
}
