@use "../../../../../../master.scss";

.setting-sidebar {
  .basic-tab {
    padding: 20px 15px 0;
    .reset-btn {
      background: transparent;
      border: none;
      margin-top: 0;
      font-size: 13px;
      color: master.$theme-color;
      margin-bottom: 20px;
      @include master.flex-start();
      svg {
        margin-right: 5px;
        font-size: 16px;
      }
    }
    .label-box {
      margin-bottom: 15px;
      @include master.flex-between();

      .form-label {
        margin: 0;
      }

      .helper {
        display: flex;
        align-items: center;
        gap: 8px;
        .helper-text {
          font-size: 13px;
        }
      }

      &.form-item {
        margin-bottom: 15px;
      }
      &.form-item:not(.lg) {
        margin-bottom: 10px;
      }
    }
    .sub-description {
      color: master.$light-black;
      margin-bottom: 10px;
      font-size: 13px;
      .bold {
        font-family: master.$font-arial-bold;
      }
    }
    .uploaded-files {
      font-size: 13px;
      margin-bottom: 20px;
      // font-family: master.$font-arial-bold;
    }
    .processing-box {
      border: 1px solid master.$light-grey;
      padding: 8px 10px;
      text-align: center;
      margin-bottom: 20px;
      svg {
        top: 1px;
        margin-right: 5px;
        animation: rotate 2s linear infinite;
      }
    }
    .manage-btn {
      display: inline-block;
      padding: 8px 20px;
      border-radius: 50px;
      margin-bottom: 20px;
      margin-top: 5px;
    }
    .viewers-input {
      margin-bottom: 20px;
    }
    .project-url-box {
      .form-label {
        margin-bottom: 5px;
        font-size: 13px;
      }
      .project-url {
        bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .url {
          display: block;
          color: master.$theme-color;
          font-size: 13px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: calc(100% - 30px);
        }
        button {
          border: none;
          background: transparent;
          transition: 0.3s all;
          width: 30px;
          height: 30px;
          border-radius: 6px;
          color: master.$theme-color;
          cursor: pointer;
          &:hover {
            background: master.$light-grey;
          }
        }
      }
    }
    .actions-wrapper {
      border-top: 1px solid master.$border-color;
      padding: 15px 0;
      @include master.flex-end();

      .action-btn {
        font-family: master.$font-arial-bold;
        border-radius: 50px;
        min-width: 90px;
        font-size: 13px;

        &:disabled {
          background: master.$grey !important;
          color: master.$black !important;
          opacity: 0.5;
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
