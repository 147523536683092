@use "../../master.scss";

.project-wrapper {
  overflow: hidden;
  .layout-wrapper {
    margin-top: 70px;
    height: calc(100vh - 70px);
    transition: 0.5s all;

    .sidebar-toggle {
      background: master.$white;
      border: 1px solid master.$border-color;
      border-radius: 50px;
      position: absolute;
      width: 34px;
      height: 34px;
      font-size: 13px;
      @include master.flex-center();
      gap: 3px;
      cursor: pointer;
      z-index: 999;
      &:hover {
        background: master.$light-grey;
      }
      @include master.media-tablet {
        display: none;
      }
    }

    &:not(.inner-page) {
      padding-left: 350px;
      padding-right: 280px;

      @include master.media-desktop {
        padding-left: 320px;
      }
      @include master.media-tablet {
        padding-left: 0;
        padding-right: 0;
      }
      &.chat-sidebar-active {
        padding-left: 0 !important;
      }
      &.setting-sidebar-active {
        padding-right: 0 !important;
      }
    }
    &.rtl:not(.inner-page) {
      padding-left: 280px;
      padding-right: 350px;

      @include master.media-desktop {
        padding-right: 320px;
      }
      @include master.media-tablet {
        padding-right: 0;
        padding-left: 0;
      }

      .chat-sidebar {
        right: auto;
        left: 0;
        border-left: none;
        border-right: 1px solid master.$border-color;
        transform: translateX(-280px);

        @include master.media-tablet {
          transform: translateX(0);
        }

        &.active {
          transform: translateX(0);

          @include master.media-tablet {
            transform: translateX(-280px);
          }
        }
      }

      .setting-sidebar {
        left: auto;
        right: 0;
        border-right: none;
        border-left: 1px solid master.$border-color;
        transform: translateX(380px);

        @include master.media-desktop {
          transform: translateX(320px);
        }
        @include master.media-tablet {
          transform: translateX(0);
        }

        &.active {
          transform: translateX(0);

          @include master.media-tablet {
            transform: translateX(320px);
          }
        }
      }
    }
    &.inner-page {
      overflow: hidden;
      overflow-y: auto;
      padding: 0 15px;
    }

    @include master.media-desktop {
      height: calc(100vh - 90px);
      margin-top: 90px;
    }

    @include master.media-smalldevice {
      height: calc(100vh - 114px);
      margin-top: 114px;
    }
  }
}
