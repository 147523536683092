@use "../../../master.scss";

.projects {
  padding-bottom: 50px;

  .table-box {
    margin: 0;

    .project-action-dropdown {
      ul {
        padding: 0 !important;
        li {
          font-size: 14px !important;
          padding: 8px 15px !important;
        }
      }
    }

    .project-name-cell {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 16px;
      line-height: 24px;
      .thumb {
        border-radius: 50px;
        height: 28px;
        width: 28px;
        text-transform: uppercase;
        background: master.$theme-color;
      }

      .text-container {
        display: flex;
        gap: 0.6rem;
        align-items: center;

        .name {
          @include master.text-wrap();
          max-width: 200px;
        }

        .like {
          height: 16px;
          width: 16px;
          &.favorite {
            color: master.$theme-color-primary;
          }
        }
      }
    }

    .project-status-cell {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        height: 32px;
        width: 32px;
        cursor: pointer;
        &:hover {
          background-color: #e8e8e852;
          border: 1px solid #e8e8e852;
        }
      }

      .draft {
        color: master.$dark-grey;
      }

      .live {
        color: master.$success-color;
      }

      .error {
        color: master.$red;
      }
    }

    .ant-table-header {
      .ant-table-cell {
        background-color: inherit;
        font-family: master.$font-arial-bold;
        font-size: 16px;
        line-height: 24px;

        .ant-table-column-sorters {
          gap: 1rem;

          .ant-table-column-title {
            font-family: master.$font-arial-bold;
          }
        }
      }
    }

    .ant-table-cell {
      padding: 16px 12px !important;
    }

    .ant-table-row {
      cursor: pointer;
      .ant-table-cell {
        padding: 16px 12px !important;
      }
    }
  }
}
