@use "../../master.scss";

.expanded-field-wrapper {
    height: 145px;

    .popup {
        .toggle {
            position: absolute;
            right: 8px;
            top: 98px;
            font-size: 12px;
            cursor: pointer;
            background: transparent;
            border: none;
        }

        .popup-title {
            font-size: 20px;
            margin-bottom: 15px;
            font-family: master.$font-arial-bold;
            display: none;
        }

        .close-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            background: transparent;
            border: none;
            cursor: pointer;
            font-size: 22px;
            display: none;
        }
    }

    &.expanded {
        .popup-box {
            min-height: 100vh;
            position: fixed;
            top: -70px;
            right: 0;
            min-width: 100vw;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;

            .backdrop {
                left: auto;
                right: 0;
                height: 100vh;
                width: 100vw;
                top: -70px;
            }

            .popup {
                background: #fff;
                z-index: 999;
                width: 680px;
                max-width: 100%;
                padding: 30px 30px 10px;
                border-radius: 8px;

                textarea {
                    height: 260px !important;
                    font-size: 15px;
                }

                .toggle {
                    right: 40px;
                    top: 305px;
                    font-size: 16px;
                }

                .popup-title,
                .close-btn {
                    display: block;
                }
            }
        }

    }

}