@use "../../master.scss";

.page-title {
  padding: 30px 0;

  .top-content {
    min-height: 60px;
    width: 100%;
    @include master.flex-between();

    .title-box {
      @include master.custom-flex(flex-end, flex-start);

      h1 {
        font-size: 24px;
        font-family: master.$font-arial-bold;
        line-height: 26px;

        @include master.media-smalldevice {
          font-size: 24px;
          margin-bottom: 5px;
        }
      }

      h6 {
        font-size: 16px;
        margin-left: 12px;

        @include master.media-smalldevice {
          margin: 0;
          font-size: 13px;
        }
      }

      @include master.media-smalldevice {
        display: block;
      }
    }

    @include master.media-tablet {
      @include master.flex-start-column();
    }
  }

  .description {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
  }

  @include master.media-smalldevice {
    padding: 20px 0;
  }
}
