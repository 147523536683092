@use "../../../../master.scss";

.setting-sidebar {
  top: 0;
  transition: 0.4s all;
  position: absolute;
  // height: calc(100% - 70px);
  height: 100%;
  width: 350px;
  max-width: 100%;
  left: 0;
  padding-top: 16px;
  background: master.$white;
  border-right: 1px solid master.$border-color;
  // pointer-events: none;
  transform: translateX(-350px);
  z-index: 99;

  .sidebar-toggle {
    left: -80px;
  }

  hr {
    margin: 0;
    border: none;
    border-top: 1px solid master.$border-color;
    margin-bottom: 15px;
  }

  .project-setting-tabs {
    .ant-tabs-nav {
      padding: 0 24px;
      margin-bottom: 4px;

      &::before {
        content: none;
      }

      .ant-tabs-nav-list {
        width: 100%;
        background: master.$ash-white;
        border-radius: 50px;
        justify-content: space-between;
        padding: 6px 8px;

        .ant-tabs-tab {
          width: 50%;
          padding: 5px 22px;
          justify-content: center;
          font-size: 15px;
          margin: 0 !important;
          border-radius: 50px;

          &:hover {
            color: master.$black;
            background: master.$offwhite;
          }

          &.ant-tabs-tab-active {
            background: master.$light-black;

            * {
              transition: none;
              color: master.$white;
            }
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }
      @include master.media-smalldevice {
        padding: 0 15px;
      }
    }

    .ant-tabs-content-holder {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      padding: 0 20px;

      .accordion-box {
        .header {
          padding: 15px 15px;
          background: transparent;
          border: none;
          width: 100%;
          @include master.flex-start();
          cursor: pointer;
          border-bottom: 1px solid master.$border-color;

          .title {
            font-size: 15px;
          }

          .toggle {
            transition: 0.3s all;
            position: absolute;
            right: 15px;
            font-size: 16px;
          }
        }

        .content {
          overflow: hidden;
          height: 0;
          transition: 0.4s all;
          background: master.$ash-white;
          padding: 0 15px;
          border: 1px solid master.$border-color-light;
          border-top: none;
          border-radius: 0 0 6px 6px;

          .label-box {
            margin-bottom: 15px;
            @include master.flex-between();

            .form-label {
              margin: 0;
            }

            &.form-item {
              margin-bottom: 15px;
            }
            &.form-item:not(.lg) {
              margin-bottom: 10px;
            }
          }
          .seperator {
            border-top: 1px solid master.$light-grey;
          }
          .sub-title {
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: master.$font-arial-bold;
            .tag {
              color: master.$white;
              background: master.$black;
              padding: 3px 12px;
              font-size: 11px;
              margin-left: 10px;
              border-radius: 50px;
            }
          }
          .sub-description {
            color: master.$light-black;
            margin-bottom: 10px;
            font-size: 13px;
            .bold {
              font-family: master.$font-arial-bold;
            }
          }

          .ant-form-item {
            margin-bottom: 15px;
          }

          .ant-input {
            padding: 4px 10px;
          }
          .ant-input-number {
            width: 100%;
          }

          .checkbox-listing {
            flex-direction: column;
            gap: 10px;

            label {
              font-size: 13px;
            }
          }

          .action-btn {
            font-family: master.$font-arial-bold;
            border-radius: 50px;
            min-width: 90px;
            font-size: 13px;

            &:disabled {
              background: master.$light-grey !important;
              color: master.$disabled-text !important;
            }
          }

          .actions-wrapper {
            border-top: 1px solid master.$border-color;
            padding: 15px 0;
            @include master.flex-end();

            .reset-btn {
              background: transparent;
              color: master.$dark-grey;
              border: none;
              font-size: 18px;
              cursor: pointer;

              &:disabled {
                cursor: auto;
                opacity: 0.6;
              }
            }
          }
        }

        &.active {
          .toggle {
            transform: rotate(180deg);
          }

          .content {
            padding-top: 15px;
            height: max-content;
          }
        }
      }
      @include master.media-smalldevice {
        padding: 0 15px;
        max-height: calc(100vh - 175px);
      }
    }
  }

  @include master.media-desktop {
    width: 320px;
    transform: translateX(-320px);
  }

  @include master.media-tablet {
    transform: translateX(0);
    // pointer-events: all;
  }

  &.active {
    // pointer-events: all;
    transform: translateX(0);

    .sidebar-toggle {
      left: -17px;
    }

    @include master.media-tablet {
      transform: translateX(-320px);
      // pointer-events: none;
    }
  }
}
