@use "../../../../../master.scss";

.copy-project-modal {
  .ant-modal-content {
    padding: 50px 45px 40px;
    @include master.flex-column();

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
      @include master.media-smalldevice {
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        &-icon {
          font-size: 14px;
        }
      }
    }

    .intro-box {
      border-bottom: 1px solid master.$light-grey;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      h2 {
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 44px;
        padding-left: 16px;
        font-family: master.$font-arial-bold;

        @include master.media-smalldevice {
          font-size: 21px;
          padding-left: 10px;
          margin-bottom: 10px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 14px);
          width: 6px;
          height: 29px;
          background: master.$theme-color-primary;
          @include master.media-smalldevice {
            height: 25px;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        width: 540px;
        max-width: 100%;
        @include master.media-smalldevice {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    label {
      display: block;
      font-size: 16px;
      font-family: master.$font-arial-bold;
      color: master.$darkest-grey;
      margin-bottom: 5px;
      @include master.media-smalldevice {
        font-size: 14px;
      }
    }
    .ant-form-item {
      margin-bottom: 16px;
      .ant-input {
        max-width: 70%;
        margin-bottom: 8px;
        @include master.media-smalldevice {
          max-width: 100%;
        }
      }
      .ant-checkbox-wrapper {
        display: flex;
        gap: 5px;

        .ant-checkbox-label {
          font-size: 16px;
          @include master.media-smalldevice {
            font-size: 13px;
          }
        }

        .ant-checkbox-inner {
          height: 22px !important;
          width: 22px !important;
          &::after {
            width: 6px;
            height: 11px;
          }
        }
      }
      @include master.media-smalldevice {
        margin-bottom: 8px;
      }
    }

    .action-box {
      margin-top: 40px;
      @include master.flex-between();

      button {
        cursor: pointer;
        border: none;
        font-size: 16px;
        min-width: 140px;
        border-radius: 30px;
        padding: 14px 25px;
        font-family: master.$font-arial-bold;

        &:nth-child(1) {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
    @include master.media-smalldevice {
      padding: 30px 25px 20px;
    }
  }
}
