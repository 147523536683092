@use "../../master.scss";

.custom-filter {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.75rem 0;

  .label {
    font-weight: bolder;
    font-family: master.$font-arial-bold;
  }

  .filter-options {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;

    .filter-option {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      background-color: master.$light-grey;
      padding: 0.4rem 0.9rem;
      border-radius: 500px; //pill shape

      .filter-text {
        font-size: 14px;
      }

      .filter-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        img {
          height: 100%;
          width: 100%;
        }
      }

      &.selected,
      &:hover {
        background-color: master.$dark-grey;
        color: master.$white;
      }
    }
  }
}
