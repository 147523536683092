@use "../../../../../master.scss";

.prompt-rating {
  .ant-modal-content {
    padding: 50px 55px 60px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
    }
    .top-header {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid master.$grey;

      .title {
        font-size: 28px;
        font-family: master.$font-arial-bold;
        padding-left: 20px;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: calc(100% - 16px);
          top: 8px;
          left: 0;
          background: master.$theme-color-primary;
        }
      }
    }
    .feedback-options {
      margin-bottom: 30px;
      @include master.flex-start();
      gap: 10px;
      button {
        background: master.$light-grey;
        cursor: pointer;
        padding: 8px 24px;
        border: none;
        border-radius: 50px;
        font-size: 15px;
        &.selected {
          background: master.$theme-color-primary;
        }
      }
    }
    .desclaimer {
      font-size: 15px;
      span {
        color: master.$theme-color;
        text-decoration: underline;
      }
    }

    .action-box {
      margin-top: 40px;
      @include master.flex-between();

      button {
        border: none;
        font-size: 16px;
        min-width: 140px;
        border-radius: 30px;
        padding: 12px 25px;
        font-family: master.$font-arial-bold;

        &:nth-child(1) {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
  }
}
