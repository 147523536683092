@use "../../../master.scss";

.create-basic-project-wrapper {
  box-shadow: 0px 3px 6px #00000029;

  .content-box {
    padding: 30px 10px;

    .title-wrapper {
      padding: 0 20px 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid master.$grey;

      h2 {
        font-size: 34px;
        padding-left: 12px;
        font-family: master.$font-arial-bold;

        @include master.media-smalldevice {
          font-size: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 12px);
          width: 7px;
          height: 24px;
          background: master.$theme-color-primary;

          @include master.media-mobile {
            height: 6px;
          }
        }
      }
      p {
        font-size: 14px;
        color: master.$dark-grey;

        @include master.media-smalldevice {
          font-size: 13px;
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
    }
    .customize-form {
      padding: 0 20px;
      .field-label {
        display: block;
        margin-bottom: 6px;
        font-family: master.$font-arial-bold;
        span {
          color: master.$venetian-red;
        }
      }
      textarea {
        height: 85px;
      }
      .ant-radio-group {
        @include master.flex-start-column();
        gap: 6px;
        @include master.media-smalldevice {
          label {
            font-size: 13px;
          }
        }
      }
      .upload-box {
        margin-top: -15px;
        padding: 8px 20px;
        min-height: auto;
        flex-direction: row;
        @include master.flex-start();
        gap: 12px;
        .thumb {
          font-size: 30px;
        }
        p {
          margin: 0 !important;
          width: auto;
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
    }

    .bottom-wrapper {
      margin-top: 35px;
      @include master.flex-end();

      button {
        font-size: 16px;
        min-width: 160px;
        border-radius: 30px;
        padding: 12px 16px;
        font-family: master.$font-arial-bold;

        &.back-btn {
          position: absolute;
          left: 0;
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }

      @include master.media-smalldevice {
        margin-top: 30px;
      }
    }

    @include master.media-smalldevice {
      padding: 30px 0;
    }
  }
}
