@use "../../master.scss";

.dropzone-upload-box {
    margin-top: 23px;
    background: master.$ash-white;
    height: 230px;
    border: 1px dashed master.$black;
    border-radius: 8px;
    @include master.flex-center-column();

    .thumb {
        font-size: 70px;
        color: master.$dark-grey;

        @include master.media-mobile {
            font-size: 30px;
        }
    }

    p {
        margin-top: 10px;
        color: master.$dark-text;
        font-size: 15px;
        line-height: 20px;
        width: 192px;
        max-width: 100%;
        font-family: master.$font-arial-bold;
        text-align: center;

        @include master.media-mobile {
            font-size: 12px;
            line-height: 15px;
        }
    }

    button {
        margin-top: 5px;
        background: master.$light-grey;
        border: none;
        border-radius: 40px;
        font-size: 15px;
        padding: 10px 35px;
        font-family: master.$font-arial-bold;
        cursor: pointer;
        transition: .3s all;

        &:hover {
            background: master.$grey;
        }

        @include master.media-mobile {
            font-size: 13px;
            padding: 6px 20px;
            margin-top: 10px;
        }
    }

    @include master.media-mobile {
        height: 150px;
        margin-top: 10px;
    }
}