@use "../../master.scss";

.dashboard-wrapper {
  .layout-wrapper {
    margin-top: 70px;
    padding-left: 260px;
    transition: 0.4s all;

    .content {
      padding: 0 20px;
      min-height: calc(100vh - 70px);
      @include master.media-smalldevice {
        min-height: calc(100vh - 80px);
      }
    }

    @include master.media-tablet {
      padding-left: 0;
    }

    &.active {
      padding-left: 0;
    }

    &.rtl {
      padding-left: 0;
      padding-right: 260px;

      .dashboard-sidebar {
        left: auto;
        right: 0;
        border-right: none;
        border-left: 1px solid master.$border-color;
        transform: translateX(260px);

        @include master.media-desktop {
          transform: translateX(240px);
        }

        @include master.media-tablet {
          transform: translateX(0);
        }

        &.active {
          transform: translateX(0);

          @include master.media-tablet {
            transform: translateX(240px);
          }
        }
      }

      @include master.media-tablet {
        padding-right: 0;
      }

      &.active {
        padding-right: 0;
      }
    }

    @include master.media-smalldevice {
      margin-top: 80px;
    }
  }
}
