@use "../../../master.scss";

.group-detail-wrapper {
    .page-title {
        .action-box {
            @include master.flex-end();
            gap: 15px;

            button {
                font-size: 15px;
                padding: 12px 26px;
                min-width: 140px;

                @include master.media-smalldevice {
                    font-size: 13px;
                    padding: 10px 20px;
                    min-width: 110px;
                }
            }

            @include master.media-smalldevice {
                margin-top: 5px;
                gap: 10px;
            }
        }
    }

    .inner-detail {
        margin-top: 40px;
        padding: 0 40px 40px;

        @include master.media-smalldevice {
            padding: 0 15px 40px;
        }
    }
}