@use "../../../../../master.scss";

.projects {
  .header {
    position: sticky;
    background: master.$white;
    top: 70px;
    padding: 30px 0;
    @include master.flex-center();
    margin-top: 70px;
    z-index: 9;
    width: 100%;

    h1 {
      position: absolute;
      left: 0;
      font-size: 34px;
      font-family: master.$font-arial-bold;
      @include master.media-tablet {
        position: unset;
        margin-bottom: 15px;
      }
      @include master.media-smalldevice {
        font-size: 24px;
      }
    }
    .search-box {
      width: 450px;
      max-width: 100%;
      padding: 7px 15px;
      border-radius: 50px;
      font-size: 16px;
      .ant-input-prefix {
        font-size: 18px;
        margin-inline-end: 10px;
      }
    }
    @include master.media-tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
