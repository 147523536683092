@use "../../../../../master.scss";

.delete-project-modal {
  .ant-modal-content {
    padding: 50px 50px 35px;
    @include master.flex-column();

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
      @include master.media-smalldevice {
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        &-icon {
          font-size: 14px;
        }
      }
    }


    h2 {
      margin-bottom: 20px;
      font-size: 40px;
      line-height: 44px;
      padding-left: 16px;
      font-family: master.$font-arial-bold;

      @include master.media-smalldevice {
        font-size: 21px;
        padding-left: 10px;
        margin-bottom: 10px;
        line-height: 25px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 6px;
        height: calc(100% - 10px);
        background: master.$theme-color-primary;
        @include master.media-smalldevice {
          width: 4px;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      width: 540px;
      max-width: 100%;
      @include master.media-smalldevice {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .action-box {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid master.$light-grey;
      @include master.flex-between();

      button {
        cursor: pointer;
        border: none;
        font-size: 16px;
        min-width: 140px;
        border-radius: 30px;
        padding: 14px 25px;
        font-family: master.$font-arial-bold;

        &:nth-child(1) {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
      @include master.media-smalldevice {
        margin-top: 20px;
        padding-top: 20px;
      }
    }
    @include master.media-smalldevice {
      padding: 30px 25px 20px;
    }
  }
}
