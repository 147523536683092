@use "../../../master.scss";

.groups-wrapper {
    padding: 0 20px;
    .create-group-btn {
        font-size: 15px;
        padding: 12px 26px;

        @include master.media-tablet {
            margin-top: 5px;
            padding: 10px 20px;
            font-size: 13px;
        }
    }

    .inner-detail {
        margin-top: 40px;

        @include master.media-smalldevice {
            padding: 0 15px 40px;           
        }
    }
}