@use "../../master.scss";

.tags-box {

    .ant-tag {
        background: master.$light-grey;
        padding: 1px 12px;
        border-radius: 50px;

        button {
            font-size: 12px;
            background: none;
            border: none;
            padding-right: 6px;
        }

        &.add-tag {
            padding: 2px;
            width: 23px;
            height: 23px;
            @include master.flex-center();
            cursor: pointer;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        &.ant-tag-hidden {
            display: block;
        }
    }

    input {
        width: 80px !important;
        font-size: 12px !important;
        padding: 2px 10px !important;
        border-radius: 50px;
        margin-inline-end: 8px;
    }
}