@use "../../master.scss";

.thankyou-modal {
  .ant-modal-content {
    padding: 60px 70px 40px;
    .top-box {
      display: flex;
      gap: 20px;
      margin-bottom: 40px;
      h2 {
        font-size: 40px;
        font-family: master.$font-arial-bold;
        margin-bottom: 10px;
        line-height: 34px;
        @include master.media-smalldevice {
          font-size: 30px;
          margin-bottom: 0;
          line-height: normal;
        }
      }
      p {
        font-size: 20px;
        @include master.media-smalldevice {
          font-size: 14px;
        }
      }
      @include master.media-smalldevice {
        gap: 15px;
        margin-bottom: 20px;
        .thumb {
          width: 30px;
        }
      }
    }

    .action-box {
      @include master.flex-between();
      button:nth-child(1) {
        background: transparent;
        border: none;
        font-size: 15px;
        color: master.$theme-color;
        text-decoration: underline;
        cursor: pointer;
        @include master.media-smalldevice {
          font-size: 13px;
        }
      }
      button:nth-child(2) {
        border-radius: 50px;
        padding: 12px 30px;
        font-size: 16px;
        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
    @include master.media-smalldevice {
      padding: 30px 25px 20px;
    }
  }
}
