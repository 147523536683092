@use "../../../master.scss";

.create-template-project-wrapper {
  box-shadow: 0px 3px 6px #00000029;

  .content-box {
    padding: 30px 0;

    .title-wrapper {
      padding: 0 20px 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid master.$grey;

      h2 {
        font-size: 34px;
        padding-left: 12px;
        font-family: master.$font-arial-bold;

        @include master.media-smalldevice {
          font-size: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 12px);
          width: 7px;
          height: 24px;
          background: master.$theme-color-primary;

          @include master.media-mobile {
            height: 6px;
          }
        }
      }
      p {
        font-size: 14px;
        color: master.$dark-grey;

        @include master.media-smalldevice {
          font-size: 13px;
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
    }
    .use-cases {
      padding: 0 20px;
      .use-case {
        border: 1px solid master.$grey;
        border-radius: 4px;
        padding: 15px 20px;
        cursor: pointer;
        transition: .3s all;

        .thumb {
          font-size: 15px;
          color: master.$theme-color;
        }
        h2 {
          font-size: 17px;
          font-family: master.$font-arial-bold;
        }
        p {
          font-size: 15px;
        }
        &:hover {
          background: master.$ash-white;
        }
      }
    }

    @include master.media-smalldevice {
      padding: 30px 0;
    }
  }
}
